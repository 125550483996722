export class Weather {
  public valid = false;
  public icon: string;
  public description: string;
  public temperature: number;
  public minTemperature: number;
  public maxTmperature: number;
  public pressure: number;
  public humidity: number;
  public time: Date;

  constructor(source: any) {
    try {
      if (source.weather && source.weather.length > 0) {
        this.icon = source.weather[0].icon;
        this.description = source.weather[0].description;
      }
      if (source.main) {
        this.temperature = Math.round(source.main.temp);
        this.minTemperature = Math.round(source.main.temp_min);
        this.maxTmperature = Math.round(source.main.temp_max);
        this.pressure = source.main.pressure;
        this.humidity = source.main.humidity;
      }
      this.time = source.dt ? new Date(source.dt * 1000) : null;
      this.valid = true;
    } catch {}
  }
}
