<div class="competences">
  <h2>Nos Competences</h2>
  <ng-template #recursiveList let-list>
    <ul>
      <li *ngFor="let item of list">
        <fa-icon [icon]="icons.faChevronRight"></fa-icon>
        <span>{{ item.value }}</span>
        <ul *ngIf="item.children">
          <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: item.children }"></ng-container>
        </ul>
      </li>
    </ul>
  </ng-template>
  <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: competences }"></ng-container>
</div>
