import { MetafrenzyGuard, MetafrenzyModule } from 'ngx-metafrenzy';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CompetencesComponent } from './components/competences/competences.component';
import { HomeComponent } from './components/home/home.component';
import { OvertimeComponent } from './components/overtime/overtime.component';
import { ServicesComponent } from './components/services/services.component';
import { TeamComponent } from './components/team/team.component';

const title = 'Pharmacie Liberté';
const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [MetafrenzyGuard],
    data: {
      metafrenzy: {
        title: `${title}`,
        tags: [
          { name: 'og:title', content: 'Pharmacie Liberté - Accueil' },
          {
            name: 'og:description',
            content: 'Pharmacie Liberté située dans le centre-ville de Bourgoin Jallieu'
          }
        ]
      }
    }
  },
  {
    path: 'equipe',
    component: TeamComponent,
    canActivate: [MetafrenzyGuard],
    data: {
      metafrenzy: {
        title: `${title} - Équipe`,
        tags: [
          { name: 'og:title', content: 'Pharmacie Liberté - Équipe' },
          {
            name: 'og:description',
            content: "L'Équipe de la Pharmacie Liberté vous accueille dans le centre-ville de Bourgoin Jallieu"
          }
        ]
      }
    }
  },
  {
    path: 'services',
    component: ServicesComponent,
    canActivate: [MetafrenzyGuard],
    data: {
      metafrenzy: {
        title: `${title} - Services`,
        tags: [
          { name: 'og:title', content: 'Pharmacie Liberté - Services' },
          {
            name: 'og:description',
            content: 'La Pharmacie Liberté met plusieurs services a votre disposition'
          }
        ]
      }
    }
  },
  {
    path: 'competences',
    component: CompetencesComponent,
    canActivate: [MetafrenzyGuard],
    data: {
      metafrenzy: {
        title: `${title} - Compétences`,
        tags: [
          { name: 'og:title', content: 'Pharmacie Liberté - Compétences' },
          {
            name: 'og:description',
            content: 'La Pharmacie Liberté met à votre disposition ses compétences'
          }
        ]
      }
    }
  },
  {
    path: 'gardes',
    component: OvertimeComponent,
    canActivate: [MetafrenzyGuard],
    data: {
      metafrenzy: {
        title: `${title} - Gardes`,
        tags: [
          { name: 'og:title', content: 'Pharmacie Liberté - Gardes' },
          {
            name: 'og:description',
            content: 'La Pharmacie Liberté vous informe des gardes'
          }
        ]
      }
    }
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [MetafrenzyModule.forRoot(), RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
