import { Component, OnInit } from '@angular/core';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faCopyright, faMapMarkedAlt, faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public readonly icons = {
    faFacebookF,
    faMapMarkedAlt,
    faPhone,
    faCopyright
  };
  public year: number;

  ngOnInit() {
    this.year = new Date().getFullYear();
  }
}
