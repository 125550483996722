import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Availability } from '../classes/availability.class';
import { Teammate } from '../classes/teammate.class';
import { Tree } from '../classes/tree.class';
import { Weather } from '../classes/weather.class';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public availability: Availability;
  public team: Teammate[];
  public services: Tree[];
  public competences: Tree[];

  constructor(private http: HttpClient) {}

  public getData(): Promise<void> {
    return new Promise((resolve, reject) =>
      this.http.get('assets/data.json').subscribe((result: any) => {
        this.availability = result.availability;
        this.team = result.team;
        this.services = result.services;
        this.competences = result.competences;
        resolve();
      }, reject)
    );
  }

  public getWeather(): Observable<Weather> {
    return this.http.get('api/?weather').pipe(map((x) => new Weather(x)));
  }

  public static Initializer(service: DataService) {
    return (): Promise<any> => service.getData();
  }
}
