<div class="header">
  <h1 class="left">
    <span routerLink="" title="Accueil">
      <fa-icon [icon]="icons.faPills" size="lg"></fa-icon>
      <span>Pharmacie Liberté</span>
    </span>
  </h1>
  <div
    class="right weather"
    *ngIf="weather && weather.valid"
    title="Météo à Bourgoin-Jallieu le {{ weather.time | date : 'fullDate' }} à {{ weather.time | date : 'shortTime' }} : {{ weather.description }} - {{ weather.temperature }}&deg;C"
  >
    <span class="icon wi wi-3x wi-owm-{{ weather.icon }}"></span>
    <div class="content">
      <p>{{ weather.description }}</p>
      <p>
        Température : <strong>{{ weather.temperature }}&deg;C</strong>
      </p>
      <p>
        Humidité : <strong>{{ weather.humidity }}%</strong>
      </p>
    </div>
  </div>
  <div class="clear"></div>
</div>
<nav>
  <ul>
    <li>
      <a title="Accueil" routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <fa-icon [icon]="icons.faHome" size="lg"></fa-icon>
        <span>Accueil</span>
      </a>
    </li>
    <li>
      <a title="Équipe" routerLink="equipe" routerLinkActive="active">
        <fa-icon [icon]="icons.faUsers" size="lg"></fa-icon>
        <span>Équipe</span>
      </a>
    </li>
    <li>
      <a title="Services" routerLink="services" routerLinkActive="active">
        <fa-icon [icon]="icons.faConciergeBell" size="lg"></fa-icon>
        <span>Services</span>
      </a>
    </li>
    <li>
      <a title="Compétences" routerLink="competences" routerLinkActive="active">
        <fa-icon [icon]="icons.faGraduationCap" size="lg"></fa-icon>
        <span>Compétences</span>
      </a>
    </li>
    <li>
      <a title="Gardes" routerLink="gardes" routerLinkActive="active">
        <fa-icon [icon]="icons.faCalendarCheck" size="lg"></fa-icon>
        <span>Gardes</span>
      </a>
    </li>
  </ul>
</nav>
