<div class="team">
  <h2>Composition de notre équipe officinale</h2>
  <div class="pure-u-1 pure-u-sm-1-2" *ngFor="let teammate of team">
    <div class="teammate">
      <fa-icon [icon]="teammate.doctor ? icons.faUserMd : icons.faUser" size="6x"></fa-icon>
      <div class="content">
        <h3>{{ teammate.name }}</h3>
        <p>
          <fa-icon [icon]="icons.faBookmark" [fixedWidth]="true"></fa-icon>
          <span>{{ teammate.position }}</span>
        </p>
        <p *ngFor="let diploma of teammate.diplomas">
          <fa-icon [icon]="icons.faGraduationCap" [fixedWidth]="true"></fa-icon>
          <span>{{ diploma }}</span>
        </p>
      </div>
    </div>
  </div>
  <h4>Notre equipe vous acceuille, vous informe et vous conseille</h4>
</div>
