<div class="home">
  <div class="pure-u-1 pure-u-sm-2-3 infos">
    <h2>Bienvenue a la pharmacie Liberté</h2>
    <div class="info pure-u-sm-1-2">
      <a href="https://goo.gl/maps/ycotXdAi7yP2" target="_blank" title="Addresse">
        <fa-icon [icon]="icons.faMapMarkedAlt" size="2x"></fa-icon>
        <div class="content">
          <p>20 rue de la Liberté</p>
          <p>38300 Bourgoin Jallieu</p>
        </div>
      </a>
    </div>
    <div class="info pure-u-sm-1-2">
      <a href="tel:+33474930178" title="Telephone">
        <fa-icon [icon]="icons.faPhone" size="2x"></fa-icon>
        <div class="content">
          <p>Telephone</p>
          <p>04 74 93 01 78</p>
        </div>
      </a>
    </div>
    <div class="info pure-u-sm-1-2">
      <a href="mailto:ph.liberte38@gmail.com" title="eMail">
        <fa-icon [icon]="icons.faEnvelope" size="2x"></fa-icon>
        <div class="content">
          <p>eMail</p>
          <p>ph.liberte38@gmail.com</p>
        </div>
      </a>
    </div>
    <h3>Membre du groupement de pharmaciens PHR "ma pharmacie reference"</h3>
  </div>
  <div class="pure-u-1 pure-u-sm-1-3 map">
    <h3>Où nous trouver ?</h3>
    <iframe
      ng-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2792.193423659953!2d5.275712515561575!3d45.586656279102684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478b2e8414dd417d%3A0x49f783d42a729481!2sPharmacie+Libert%C3%A9!5e0!3m2!1sen!2sfr!4v1553720951517"
      allowfullscreen=""
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2792.193423659953!2d5.275712515561575!3d45.586656279102684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478b2e8414dd417d%3A0x49f783d42a729481!2sPharmacie+Libert%C3%A9!5e0!3m2!1sen!2sfr!4v1553720951517"
    ></iframe>
  </div>
  <div class="opening-times">
    <div class="days">
      <div class="day status-{{ day.isOpen ? 'opened' : 'closed' }}" *ngFor="let day of availability.days">
        <fa-icon [icon]="day.isOpen ? icons.faCheck : icons.faTimes" size="2x"></fa-icon>
        <p>{{ day.date | date : 'fullDate' }}</p>
        <p>{{ day.isOpen ? 'Ouvert' : 'Fermé' }}</p>
        <p *ngIf="day.holiday">{{ day.holiday }}</p>
        <p *ngFor="let range of day.ranges">{{ range.startTime }} - {{ range.endTime }}</p>
      </div>
    </div>
    <p *ngIf="availability.isNowOpen" class="current status-opened">
      <fa-icon [icon]="icons.faDoorOpen" size="lg"></fa-icon>
      <span>Actuellement ouvert</span>
    </p>
    <p *ngIf="!availability.isNowOpen" class="current status-closed">
      <fa-icon [icon]="icons.faDoorClosed" size="lg"></fa-icon>
      <span>Actuellement fermé</span>
    </p>
  </div>
  <div class="logos">
    <a class="afssaps" href="https://ansm.sante.fr" target="_blank" title="Agence Nationale de Sécurité du Médicament et des Produits de Santé"></a>
    <a class="ars" href="https://www.ars.sante.fr" target="_blank" title="Agence régionale de santé"></a>
    <a class="onp" href="http://www.ordre.pharmacien.fr/" target="_blank" title="Ordre national des pharmaciens"></a>
  </div>
</div>
