import { Tree } from 'src/app/classes/tree.class';
import { DataService } from 'src/app/services/data.service';

import { Component, OnInit } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  public readonly icons = {
    faChevronRight
  };
  public services: Tree[];

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.services = this.dataService.services;
  }
}
