import { Weather } from 'src/app/classes/weather.class';
import { DataService } from 'src/app/services/data.service';

import { Component, OnInit } from '@angular/core';
import { faCalendarCheck, faConciergeBell, faGraduationCap, faHome, faPills, faUsers } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public readonly icons = {
    faPills,
    faHome,
    faUsers,
    faConciergeBell,
    faGraduationCap,
    faCalendarCheck
  };
  public weather: Weather;

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.dataService.getWeather().subscribe((w) => (this.weather = w));
  }
}
