import { Teammate } from 'src/app/classes/teammate.class';
import { DataService } from 'src/app/services/data.service';

import { Component, OnInit } from '@angular/core';
import { faBookmark, faGraduationCap, faUser, faUserMd } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
  public readonly icons = {
    faUser,
    faUserMd,
    faBookmark,
    faGraduationCap
  };
  public team: Teammate[];

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.team = this.dataService.team;
  }
}
