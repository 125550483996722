<div class="footer">
  <div class="left links">
    <a href="https://www.facebook.com/profile.php?id=100011882590229" target="_blank" title="Facebook">
      <fa-icon [icon]="icons.faFacebookF" size="lg"></fa-icon>
      <span>Facebook</span>
    </a>
    <a href="tel:+33474930178" target="_blank" title="Telephone">
      <fa-icon [icon]="icons.faPhone" size="lg"></fa-icon>
      <span>Nous appeller...</span>
    </a>
    <a href="https://goo.gl/maps/ycotXdAi7yP2" target="_blank" title="Addresse">
      <fa-icon [icon]="icons.faMapMarkedAlt" size="lg"></fa-icon>
      <span>Nous trouver...</span>
    </a>
  </div>
  <div class="right copyright">
    <span>Pharmacie Liberté</span>
    <fa-icon [icon]="icons.faCopyright" size="lg"></fa-icon>
    <span>{{ year }}</span>
  </div>
  <div class="clear"></div>
</div>
