import * as moment from 'moment-ferie-fr';
import { DataService } from 'src/app/services/data.service';

import { Component, OnInit } from '@angular/core';
import {
    faCheck, faDoorClosed, faDoorOpen, faEnvelope, faFax, faMapMarkedAlt, faPhone, faTimes
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public readonly icons = {
    faMapMarkedAlt,
    faPhone,
    faFax,
    faEnvelope,
    faCheck,
    faTimes,
    faDoorOpen,
    faDoorClosed
  };
  public availability: any;

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.loadAvailability();
  }

  private loadAvailability(): void {
    const config = this.dataService.availability.days,
      excConfig = this.dataService.availability.exceptions,
      days = [0, 1, 2, 3, 4, 5, 6].reduce((arr, idx) => {
        const currDay = moment().startOf('day').add(idx, 'd'),
          configDate = currDay.format('L'),
          configDay = configDate in excConfig ? excConfig[configDate] : config[currDay.day() - 1];
        arr.push({
          date: currDay.toDate(),
          isToday: moment().isSame(currDay, 'day'),
          isOpen: configDay && !currDay.getFerie(),
          ranges: configDay,
          holiday: currDay.getFerie()
        });
        return arr;
      }, []),
      selectedDay = days.find((d) => d.isToday);
    this.availability = {
      days,
      isNowOpen: selectedDay.isOpen && selectedDay.ranges.some((r) => moment().isBetween(moment(r.startTime, 'HH:mm'), moment(r.endTime, 'HH:mm')))
    };
  }
}
