import { Tree } from 'src/app/classes/tree.class';
import { DataService } from 'src/app/services/data.service';

import { Component, OnInit } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-competences',
  templateUrl: './competences.component.html',
  styleUrls: ['./competences.component.scss']
})
export class CompetencesComponent implements OnInit {
  public readonly icons = {
    faChevronRight
  };
  public competences: Tree[];

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.competences = this.dataService.competences;
  }
}
